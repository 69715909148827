import React from "react";
import styles from "./MainTitle.module.scss";
import { Link } from "gatsby";
import { Button } from "react-bootstrap";

function MainTitle({ title, linkTo }) {
  return (
    <div className={styles.MainTitle}>
      <div className={styles.title}>{title}</div>
      {!!linkTo && (
        <Link to={linkTo} className={styles.moreLink}>
          <Button variant="light" className={styles.more}>
            + 더보기
          </Button>
        </Link>
      )}
    </div>
  );
}

export default MainTitle;

import React, { Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import LayoutHeader from "./LayoutHeader";
import LayoutFooter from "./LayoutFooter";

const Layout = ({ location, children, bgColor }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  let layoutHasSidebar = false;
  if (location.pathname.match(/^\/(docs)/)) {
    layoutHasSidebar = true;
  }

  return (
    <Fragment>
      <LayoutHeader
        location={location}
        siteTitle={data.site.siteMetadata.title}
        bgColor={bgColor}
      />
      {children}
      <LayoutFooter layoutHasSidebar={layoutHasSidebar} />
    </Fragment>
  );
};

export default Layout;

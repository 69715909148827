import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import classNames from "classnames/bind";
import styles from "./LayoutFooter.module.scss";
import logo from "../../../content/assets/app-icon-black.png";
import Anchor from "../Common/Anchor";
import footerNav from "../../../content/footerNav.json";

const cx = classNames.bind(styles);

const LayoutFooter = () => {
  return (
    <footer className={cx("footerSection")}>
      <Container>
        <Row className={cx("footerMain")}>
          <Col lg={{ span: 6, order: 0 }} xs={{ span: 12, order: 1 }}>
            <div className={cx("flexWrapper")}>
              <img
                src={logo}
                alt="logo"
                width="60px"
                height="60px"
                className={cx("logoIcon")}
              />
              <div className={cx("flexItem")}>
                <div className={cx("footerTitle")}>TutorialPost</div>
                <div>
                  Made with{" "}
                  <Anchor
                    external
                    url={"https://www.gatsbyjs.org/"}
                    className={cx("anchor")}
                  >
                    Gatsby
                  </Anchor>
                  ,{" "}
                  <Anchor
                    external
                    url={"https://getbootstrap.com/"}
                    className={cx("anchor")}
                  >
                    Bootstrap
                  </Anchor>{" "}
                  and tons of{" "}
                  <span role="img" aria-label="coffee">
                    ☕️
                  </span>
                </div>
                <div>
                  이 웹사이트의 저작물은{" "}
                  <Anchor
                    external
                    url={"http://creativecommons.org/licenses/by-nc/4.0/"}
                    className={cx("anchor")}
                  >
                    크리에이티브 커먼즈 저작자표시 - 비영리 4.0 국제 라이선스
                  </Anchor>
                  에 따라 이용할 수 있습니다.
                </div>
                <Anchor
                  external
                  url={"http://creativecommons.org/licenses/by-nc/4.0/"}
                >
                  <img
                    alt="크리에이티브 커먼즈 라이선스"
                    src="https://i.creativecommons.org/l/by-nc/4.0/80x15.png"
                  />
                </Anchor>
              </div>
            </div>
          </Col>

          <Col className="mb-3">
            <div className={cx("footerTitle")}>Link</div>
            {footerNav.link.map(entry => {
              return (
                <div key={entry.url}>
                  <Anchor
                    external={entry.external}
                    url={entry.url}
                    className={cx("anchor")}
                  >
                    {entry.name}
                  </Anchor>
                </div>
              );
            })}
          </Col>

          <Col className="mb-3">
            <div className={cx("footerTitle")}>Social</div>
            {footerNav.social.map(entry => {
              return (
                <div key={entry.url}>
                  <Anchor
                    external={entry.external}
                    url={entry.url}
                    className={cx("anchor")}
                  >
                    {entry.name}
                  </Anchor>
                </div>
              );
            })}
          </Col>

          <Col className="mb-3">
            <div className={cx("footerTitle")}>Etc.</div>
            {footerNav.etc.map(entry => {
              return (
                <div key={entry.url}>
                  <Anchor
                    external={entry.external}
                    url={entry.url}
                    className={cx("anchor")}
                  >
                    {entry.name}
                  </Anchor>
                </div>
              );
            })}
          </Col>
        </Row>

        <Row className={cx("footerBottom")}>
          <Col className="text-center py-3 m-0">
            &copy; 2018-{new Date().getFullYear()} TutorialPost. All Rights
            Reserved.
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default LayoutFooter;

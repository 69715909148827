import React from "react";
import { Link } from "gatsby";
import { Container, Navbar } from "react-bootstrap";
import { MdMenu } from "react-icons/md";
import styles from "./LayoutHeader.module.scss";
import classNames from "classnames/bind";
import headerNav from "../../../content/headerNav.json";
import logo from "../../../content/assets/app-icon.png";

const cx = classNames.bind(styles);

const HeaderLink = ({ external, name, url }) =>
  !external ? (
    <Link
      to={url}
      className={cx(["navMenu"], ["nav-item nav-link"])}
      activeClassName={cx("active")}
      partiallyActive
    >
      {name}
    </Link>
  ) : (
    <a
      href={url}
      className={cx(["navMenu"], ["nav-item nav-link"])}
      target="_blank"
      rel="nofollow noopener noreferrer"
    >
      {name}
    </a>
  );

const LayoutHeader = ({ location, siteTitle, bgColor = "#ffffff" }) => {
  return (
    <header>
      <Navbar
        expand="md"
        variant="x"
        fixed="top"
        className={cx("navbarShadow")}
        style={{ backgroundColor: bgColor }}
      >
        <Container>
          <Navbar.Brand>
            <Link to="/" className={cx("logoLink")}>
              <img
                src={logo}
                alt="TutorialPost logo"
                width="30"
                height="30"
                className={cx("logoImg")}
              />
              <span className={cx("logoText")}>{siteTitle}</span>
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className={cx("toggler")}
          >
            <MdMenu size={26} />
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <div className="navbar-nav ml-auto">
              {headerNav.map(({ name, url, external }) => (
                <HeaderLink
                  key={name}
                  name={name}
                  url={url}
                  external={external}
                />
              ))}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default LayoutHeader;

import React from "react";
import { Link } from "gatsby";

const Anchor = ({ children, external = false, url, className }) =>
  !external ? (
    <Link to={url} className={className}>
      {children}
    </Link>
  ) : (
    <a
      href={url}
      className={className}
      target="_blank"
      rel="nofollow noopener noreferrer"
    >
      {children}
    </a>
  );

export default Anchor;
